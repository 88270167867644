import { Query, SanitySeo } from "@graphql-types";
import { useStaticQuery, graphql } from "gatsby";
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

interface Props {
  seoData?: SanitySeo | null;
  post?: boolean;
  overwriteTitle?: string | null;
  overwriteImage?: string | null;
  overwriteDescription?: string | null;
  slug?: string;
  recipe?: boolean;
  children?: ReactNode;
}

const SEO = (props: Props) => {
  const { sanitySiteConfig }: Query = useStaticQuery(graphql`
    query defaultSEOQuery {
      sanitySiteConfig {
        defaultSEO {
          pageDescription
          pageKeyWords
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  const pageUrl = `${process.env.GATSBY_BASE_URL}/${props.slug}`;
  const pageDescription =
    props.overwriteDescription ||
    props.seoData?.pageDescription ||
    sanitySiteConfig?.defaultSEO?.pageDescription ||
    undefined;
  const pageTitle =
    props.overwriteTitle || props.seoData?.pageTitle || sanitySiteConfig?.defaultSEO?.pageTitle || undefined;
  const pageImage =
    props.overwriteImage ||
    props.seoData?.ogImage?.asset?.url ||
    sanitySiteConfig?.defaultSEO?.ogImage?.asset?.url ||
    undefined;

  return (
    <div className="seo">
      <Helmet title={pageTitle}>
        <meta name="description" content={pageDescription} />
        <meta
          name="keywords"
          content={props.seoData?.pageKeyWords ?? sanitySiteConfig?.defaultSEO?.pageKeyWords ?? undefined}
        />
        <html lang="en" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:type" content={props.post ? `article` : props.recipe ? "recipe" : `website`} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content={pageImage} />
        <meta property="og:description" content={pageDescription} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:image" content={pageImage} />
        <meta property="twitter:site" content="@annabellangbein" />
        <meta name="twitter:description" content={pageDescription} />
        {props.children}
      </Helmet>
    </div>
  );
};

export default SEO;
