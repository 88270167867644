import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Image, Video } from "@components/global";
import { SanityBlog } from "@graphql-types";
import { useStore } from "@state/store";
import { colorsRGB, icons, TABLET_BREAKPOINT } from "@util/constants";
import { handleSaveBlog } from "@util/helper";
import { Container, H2, P } from "@util/standard";
import { useCheckScreenWidth } from "@util/hooks";
import ModalBox from "@components/global/modalBox";

interface Props {
  data: SanityBlog;
  blogId: string;
}

const Gradient = styled(Container)`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 46.88%, rgba(0, 0, 0, 0.6) 100%);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
`;

const Icon = styled.img`
  margin-right: 15px;
`;

const PlayButton = styled.img`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  right: 28%;
  transform: translate(-72%, -50%);
  cursor: pointer;
  z-index: 3;
  &:hover {
    width: 80px;
    height: 80px;
    transform: translate(-40%, -50%);
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 67px;
    height: 67px;
    top: 40%;
    right: 50%;
    transform: translate(50%, -40%);
  }
`;

function BlogHero({ data, blogId }: Props) {
  const [isSaved, setIsSaved] = useState<boolean>();
  const [popUpVisible, setPopUpVisible] = useState(false);
  const { user, savedBlogs, isLoggedIn } = useStore();
  const { isLaptopWidth } = useCheckScreenWidth();

  const blogMatch = () => {
    const blogMatches = savedBlogs?.filter(blog => blog === blogId);
    blogMatches && blogMatches.length > 0 ? setIsSaved(true) : setIsSaved(false);
  };

  const checkSaved = () => {
    if (!isLoggedIn) {
      useStore.setState({ authMenuOpen: true });
    }
    if (user == null || blogId == null || user.uid == null) {
      return null;
    }
    handleSaveBlog({
      id: blogId,
      savedBlogs: savedBlogs ? savedBlogs : [],
      isUserSignedIn: true,
      userUId: user.uid,
    });
    return;
  };

  useEffect(() => {
    blogMatch();
  });

  return (
    <>
      <Container width="100%" margin="0 0 15px 0">
        <Container
          height={data.blogVideo && data.blogVideo.url ? "700px" : "600px"}
          tabletHeight="100vh"
          position="relative"
          width="100%"
          mobileHeight="80vh"
        >
          {data.featureImage && <Image isBackground image={data.featureImage?.asset?.gatsbyImageData} />}
          {data.blogVideo && data.blogVideo.url && (
            <Container width="100%" height="100%" position="absolute">
              <Video
                background
                hidePlayButton
                width={isLaptopWidth ? "140vw" : "320vw"}
                height="150vh"
                controls={false}
                url={data.blogVideo.url as string}
                id={data.blogVideo.url as string}
              />
              {!popUpVisible && <PlayButton src={icons.playButton} onClick={() => setPopUpVisible(true)} />}
            </Container>
          )}
          <Gradient />
          <Container
            margin="0 0 0 9%"
            tabletMargin="0"
            width="48%"
            tabletWidth="95%"
            flexDirection="column"
            zIndex={2}
            justifyContent="flex-end"
          >
            <Container tabletMargin="0 0 0 15px">
              {data.category && (
                <P color="white" margin="20px 0 5px 0">
                  {data.category?.name?.toUpperCase()}
                </P>
              )}
              {data.subCategory && data.subCategory[0] && (
                <P color="white" margin="20px 10px 5px 10px">
                  |
                </P>
              )}
              {data.subCategory && data.subCategory[0] && (
                <P color="white" margin="20px 0 5px 0">
                  {data.subCategory[0].name?.toUpperCase()}
                </P>
              )}
            </Container>
            <H2 margin={isLaptopWidth ? "15px 0 0 0" : "15px 0 0 15px"} color="white">
              {data.blogTitle}
            </H2>
            {data.author && (
              <P margin={isLaptopWidth ? "40px 0 80px 0" : "30px 0 50px 15px"} color="white">
                By {data.author}
              </P>
            )}
            <Container backgroundColor="pink" width="max-content" onClick={() => checkSaved()} cursor="pointer">
              <Container margin="15px 20px">
                <Icon src={isSaved ? icons.saved : icons.unSaved} />
                <P color="white" margin="auto">
                  {isSaved ? "Unsave Article" : "Save Article"}
                </P>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      {popUpVisible && data.blogVideo && (
        <ModalBox visible={popUpVisible} setVisible={setPopUpVisible} isVideo>
          <Video controls volume hidePlayButton url={data.blogVideo.url as string} id={data.blogVideo.url as string} />
        </ModalBox>
      )}
    </>
  );
}

export default BlogHero;
