import React from "react";

import { SanityCta, SanityProduct } from "@graphql-types";
import { ButtonTheme, isSanityLink, isSanityProduct } from "@util/types";
import { Button } from ".";
import { addItemToCart, getProduct } from "@util/shopifyHelper";
import { useStore } from "@state/store";
import { toast } from "react-toastify";
import CartSuccessToast from "./cartSuccessToast";

interface Props {
  data: SanityCta;
  whiteBackground?: boolean;
  padding?: string;
}

function Cta({ data, whiteBackground, padding }: Props) {
  if (data == null) {
    return null;
  }

  const { checkout } = useStore();

  const { ctaStyle, ctaType } = data;

  if (ctaType == null || ctaStyle == null || ctaType[0] == null) {
    return null;
  }

  const handleCartButtonClick = (item: SanityProduct) => {
    if (checkout == null || checkout.id == null) {
      toast.error("There was a problem adding this item to your Cart.");
      return null;
    }
    getProduct(item).then(itemData => {
      if (itemData[0].id == null) {
        toast.error("There was a problem adding this item to your Cart.");
      }

      const productId = itemData[0].variants[0].id.toString();

      addItemToCart(checkout.id, productId, 1);
      toast.success(CartSuccessToast);
    });
    return;
  };

  if (isSanityLink(ctaType[0]) && ctaType[0].url) {
    return (
      <Button
        theme={whiteBackground ? (`${ctaStyle}` as ButtonTheme) : (`${ctaStyle}White` as ButtonTheme)}
        text={ctaType[0].linktext as string}
        linkTo={ctaType[0].url}
        padding={padding}
      />
    );
  }

  if (isSanityProduct(ctaType[0])) {
    return (
      <Button
        theme={whiteBackground ? (`${ctaStyle}` as ButtonTheme) : (`${ctaStyle}White` as ButtonTheme)}
        text="Add to Cart"
        onClick={() => handleCartButtonClick(ctaType[0] as SanityProduct)}
        padding={padding}
      />
    );
  }
  return null;
}

export default Cta;
