import React from "react";

import { SanityRecipePreview } from "@graphql-types";
import { Container, H3, P } from "@util/standard";
import { Button } from ".";
import RecipeCard from "./recipeCard";
import { useCheckScreenWidth } from "@util/hooks";
import { fontWeights } from "@util/constants";

interface Props {
  data: SanityRecipePreview;
}

function RecipePreview({ data }: Props) {
  const { isLaptopWidth } = useCheckScreenWidth();

  if (data == null) {
    return null;
  }
  return (
    <Container width="82%" margin="100px auto" flexDirection="column">
      <Container>
        {data.header && (
          <H3 mobileFontSize={28} margin="0 25px 0 0 " fontFamily="proximaNova" fontWeight="semibold" >
            {data.header}
          </H3>
        )}
        {data.blockLink && data.blockLink.url && (
          <Button
            padding={isLaptopWidth ? "15px 0 0 0" : "10px 0 0 0"}
            theme="textBlack"
            text={data.blockLink.linktext as string}
            linkTo={data.blockLink.url as string}
          />
        )}
      </Container>
      {data.recipeRefs && (
        <Container width="100%" flexDirection={isLaptopWidth ? "row" : "column"} position="relative">
          {data.recipeRefs.map((recipe, index) => {
            if (recipe == null || data.recipeRefs == null) {
              return null;
            }

            const isLast = index === data.recipeRefs.length - 1;

            return (
              <RecipeCard
                recipeId={recipe._id as string}
                data={recipe}
                margin={isLast ? "20px 0px 0 0" : "20px 15px 0 0"}
                key={recipe._key}
              />
            );
          })}
        </Container>
      )}
    </Container>
  );
}

export default RecipePreview;
