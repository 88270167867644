import { Image, Video } from "@components/global";
import ModalBox from "@components/global/modalBox";
import { SanityBlog } from "@graphql-types";
import { useStore } from "@state/store";
import { icons, MOBILE_BREAKPOINT } from "@util/constants";
import { handleSaveBlog } from "@util/helper";
import { Container, H4, P, A, LinkContainer } from "@util/standard";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface Props {
  data: SanityBlog;
  blogId: string;
}

const SaveIcon = styled.img`
  width: 14px;
  height: 20px;
  margin: auto;
`;

const PlayButton = styled.img`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  &:hover {
    width: 80px;
    height: 80px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 67px;
    height: 67px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledLinkContainer = styled(LinkContainer)`
  height: 100%;
  .gatsby-image-wrapper > img {
    transform: scale(1);
    transition: transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  &:hover {
    .gatsby-image-wrapper > img {
      transform: scale(1.1);
      transition: transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }
`;

function BlogGridCard({ data, blogId }: Props) {
  if (data == null) {
    return null;
  }

  const [isSaved, setIsSaved] = useState<boolean>();
  const [popUpVisible, setPopUpVisible] = useState(false);
  const { user, savedBlogs, isLoggedIn } = useStore();

  const blogMatch = () => {
    const blogMatches = savedBlogs?.filter(blog => blog === blogId);
    blogMatches && blogMatches.length > 0 ? setIsSaved(true) : setIsSaved(false);
  };

  const checkSaved = () => {
    if (!isLoggedIn) {
      useStore.setState({ authMenuOpen: true });
    }
    if (user == null || blogId == null || user.uid == null) {
      return null;
    }
    handleSaveBlog({
      id: blogId,
      savedBlogs: savedBlogs ? savedBlogs : [],
      isUserSignedIn: true,
      userUId: user.uid,
    });
    return;
  };

  useEffect(() => {
    blogMatch();
  });

  return (
    <Container position="relative" flexDirection="column">
      <Container width="100%" height="380px" tabletHeight="257px" position="relative">
        {data.blogVideo && data.blogVideo.image ? (
          <Container width="100%" height="100%">
            <Video
              useThumbnail
              hidePlayButton
              thumbnail={data.blogVideo.image}
              url={data.blogVideo.url as string}
              id={data.blogVideo.url as string}
            />
            <PlayButton src={icons.playButton} onClick={() => setPopUpVisible(true)} />
          </Container>
        ) : (
          <StyledLinkContainer to={`/learn/${data.slug?.current}` as string}>
            <Container width="100%" height="100%">
              <Image image={data.featureImage?.asset?.gatsbyImageData} objectFit="cover" />
            </Container>
          </StyledLinkContainer>
        )}
      </Container>
      <Container>
        {data.category && <P margin="20px 0 5px 0">{data.category?.name?.toUpperCase()}</P>}
        {data.blogSubCategories && data.blogSubCategories[0] && <P margin="20px 5px 5px 5px">|</P>}
        {data.blogSubCategories && data.blogSubCategories[0] && (
          <P margin="20px 0 5px 0">{data.blogSubCategories[0].toUpperCase()}</P>
        )}
      </Container>
      <A href={`/learn/${data.slug?.current}` as string}>
        <H4 fontWeight="normal" margin="0px">
          {data.blogTitle}
        </H4>
      </A>
      {data.author && <P>{data.author}</P>}
      <Container
        onClick={() => checkSaved()}
        cursor="pointer"
        backgroundColor={isSaved ? "wanakaBlue" : "pink"}
        width="50px"
        height="50px"
        position="absolute"
        top="0px"
        right="0px"
      >
        <SaveIcon src={isSaved ? icons.saved : icons.unSaved} />
      </Container>
      {popUpVisible && data.blogVideo && (
        <ModalBox visible={popUpVisible} setVisible={setPopUpVisible} isVideo>
          <Video controls volume hidePlayButton url={data.blogVideo.url as string} id={data.blogVideo.url as string} />
        </ModalBox>
      )}
    </Container>
  );
}

export default BlogGridCard;
