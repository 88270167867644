import React from "react";

import { Button } from "@components/global";
import { SanitySuggestedBlogs } from "@graphql-types";
import { useCheckScreenWidth } from "@util/hooks";
import { Container, GridContainer, H3 } from "@util/standard";
import BlogGridCard from "./blogGridCard";

interface Props {
  data: SanitySuggestedBlogs;
}

function RelatedBlogs({ data }: Props) {
  const { isLaptopWidth } = useCheckScreenWidth();

  if (data == null) {
    return null;
  }
  return (
    <Container width="82%" margin="100px auto" flexDirection="column">
      <Container>
        {data.header && (
          <H3 mobileFontSize={22} margin="0 25px 0 0 " fontFamily="proximaNova">
            {data.header}
          </H3>
        )}
        {data.blockLink && data.blockLink.url && (
          <Button
            padding={isLaptopWidth ? "15px 0 0 0" : "10px 0 0 0"}
            theme="textBlack"
            text={data.blockLink.linktext as string}
            linkTo={data.blockLink.url as string}
          />
        )}
      </Container>
      {data.blogRefs && (
        <GridContainer width="100%" repeat={3} tabletRepeat={1} columnGap="15px" rowGap={40}>
          {data.blogRefs.map((blog, index) => {
            if (blog == null) {
              return null;
            }
            if (!isLaptopWidth && index > 1) {
              return null;
            }
            return <BlogGridCard blogId={blog._id as string} data={blog} key={blog._key} />;
          })}
        </GridContainer>
      )}
    </Container>
  );
}

export default RelatedBlogs;
