import React, { useState } from "react";
import styled from "styled-components";

import {
  Maybe,
  SanityCategoryPanelPreview,
  SanityFlavoursOrOccasionsOrRecipeCategoriesOrSpecialDiets,
} from "@graphql-types";
import { Container, H2, P, Gradient } from "@util/standard";
import { Button, Image } from ".";
import { colors, fontWeights, gradients, MOBILE_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { useStore } from "@state/store";
import { navigate } from "gatsby";

interface Props {
  data: SanityCategoryPanelPreview;
}

interface PanelProps {
  category: SanityFlavoursOrOccasionsOrRecipeCategoriesOrSpecialDiets;
  index: number;
}

const StyledContainer = styled(Container)`
  .gatsby-image-wrapper > img {
    transform: scale(1);
    transition: transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  &:hover {
    .gatsby-image-wrapper > img {
      transform: scale(1.1);
      transition: transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }
`;

const Title = styled(H2)`
  z-index: 2;
  color: ${colors.white};
  font-weight: ${fontWeights.bold};
  margin: 10px 0;
`;

const ScrollContainer = styled.div`
  overflow: hidden;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

function CategoryPanelPreview({ data }: Props) {
  if (data == null) {
    return null;
  }

  const { isMobileWidth } = useCheckScreenWidth();
  const { setHasSearched } = useStore();

  const handleClick = (category: string) => {
    useStore.setState({
      searchFilters: [category],
      currentSearch: "",
    });
    setHasSearched(true);
    navigate("/recipes");
  };

  const PanelCard = ({ category, index }: PanelProps) => {
    if (category == null) {
      return null;
    }

    return (
      <StyledContainer
        position="relative"
        cursor="pointer"
        width={index === 0 || index === 3 ? "59%" : "40%"}
        mobileWidth="285px"
        margin={index === 0 || index === 2 ? "0px 1% 1% 0px" : "0px 0px 1% 0px"}
        mobileMargin="0 0 0 15px"
        onClick={() => handleClick(category.name ? category.name : "")}
      >
        <Container
          flexDirection="column"
          position="absolute"
          bottom={isMobileWidth ? "20px" : "40px"}
          left={isMobileWidth ? "20px" : "40px"}
        >
          <Title>{category.name}</Title>
          {category.description && (
            <P color="white" zIndex={2} width="60%" mobileWidth="100%" margin="0px 0px 20px 0px">
              {category.description}
            </P>
          )}
          <Button text="View Recipes" width="max-content" theme="blockWhite" />
        </Container>
        <Image minHeight="400px" isBackground image={category.image?.asset?.gatsbyImageData} />
        <Gradient gradient={gradients.gradientFromBottom} />
      </StyledContainer>
    );
  };

  return (
    <ScrollContainer>
      <Container width="98%" flexWrap="wrap" margin="0 auto" mobileWidth="max-content" mobileMargin="0 0 15px 0">
        {data.categoryRefs?.map((category, index) => {
          if (category == null) {
            return null;
          }
          return <PanelCard category={category} index={index} />;
        })}
      </Container>
    </ScrollContainer>
  );
}

export default CategoryPanelPreview;
